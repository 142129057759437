<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：系统设置> <b>密码修改</b></span>
        </div>
        <div class="input-info">
            <div class="reset-pass-input flex-center">
                <el-input type="password" v-model="old_pass" placeholder="请输入旧密码"></el-input>
            </div>
            <div class="reset-pass-input flex-center">
                <el-input type="password" v-model="new_pass" placeholder="请输入新密码"></el-input>
            </div>
            <div class="reset-pass-input flex-center">
                <el-input type="password" v-model="new_rePass" placeholder="请输入确认新密码"></el-input>
            </div>
            <el-button type="primary" class="submit-btn" @click="submit">提交</el-button>
        </div>
    </div>
</template>

<script>

    export default {
        name: "passSet",
        data() {
            return {
                old_pass: '',
                new_pass: '',
                new_rePass: ''
            }
        },
        created() {
            this.$emit('setIndex', [3, 1])
        },
        methods: {

            submit() {
                if (!this.old_pass) {
                    this.$message.error('请输入旧密码')
                    return
                }
                if (!this.new_pass) {
                    this.$message.error('请输入新密码')
                    return
                }
                if (!this.new_rePass) {
                    this.$message.error('请输入确认新密码')
                    return
                }
                if (this.old_pass == this.new_pass) {
                    this.$message.error('新密码和旧密码不能相同')
                    return
                }
                if (this.new_rePass != this.new_pass) {
                    this.$message.error('两次新密码不相同')
                    return
                }
                this.$api.apiContent.setPass({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    oldpass: this.old_pass,
                    pass: this.new_pass,
                    repass: this.new_rePass,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        oldpass: this.old_pass,
                        pass: this.new_pass,
                        repass: this.new_rePass,
                    }, this.$store.state.user.key)
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功,请重新登录'
                    });
                    this.$store.state.user = null

                    this.$router.push('/login')
                }).catch(e => {
                    this.$message.info(e.msg)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }
        }

        .input-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 700px;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .submit-btn {
                width: 50%;
                height: 50px;
                margin-top: 50px;
                background-color: #3b77e7;
                border-radius: 6px;
                font-size: 21px;
                letter-spacing: 10px;
            }
        }
    }
</style>